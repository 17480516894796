
























import { Observer } from 'mobx-vue'
import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import { MyPageViewModel } from '../../viewmodels/my-page-viewmodel'

@Observer
@Component({
  components: {
    'top-post-list': () => import('@/modules/community/home/components/my-page/top-post/top-post-list.vue'),
    observer: () => import('vue-intersection-observer'),
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
  },
})
export default class TopPost extends Vue {
  @Inject() vm!: MyPageViewModel

  @Watch('$vuetify.breakpoint.width', { immediate: true }) widthChange(value) {
    if (value <= 1480) {
      this.vm.changeListView(true)
    }
  }

  loadMore(entry) {
    if (this.vm.topPostsCanLoadMore) {
      if (entry.isIntersecting) this.vm.topPostsLoadMore()
    }
  }
}
